@import './src/scss/variables';
@import './src/scss/app';

.means-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .means-item {
    background-color: #b1d5b1;
    flex: 1 1 auto;
    margin: 5px;
    padding: 5px;
    border-radius: 10px;

    .means-header {
      background-color: #b1c7d5;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .means-weight:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}